import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Hello, world!",
  "date": "2022-04-06T00:00:00.000Z"
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Hello, world!`}</h1>
    <p>{`leme`}</p>
    <h1>{props.pageContext.frontmatter.title}</h1>
    <span>{props.pageContext.frontmatter.author}</span>
(Blog post content, components, etc.)
    <h1>{`My Awesome Page`}</h1>
    <p>{`Here's a paragraph, followed by a paragraph with data!`}</p>
    <p>{props.data.site.siteMetadata.description}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      